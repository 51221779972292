
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import { ApiOrdersProcessPaymentsPostRequest, BillingDaysListViewModel, OrderSubStatesEnum, PagedResultOfPaymentDueOrdersViewModel, PaymentMethodStatusEnum } from "@/api-client";
import { handleQueries } from "@/utils/handleQueries"
import { ordersPaymentsDueGet, ordersProcessPaymentsPost } from "@/api/orders";
import { formatDate } from "@/utils/formatDate";
import { formatName, orderSubStatesFilter } from "@/utils";
import { debounce } from 'vue-debounce'
import { billingDaysDdlGet } from "@/api/payments";

type PaymentMethodStatusWithReset = PaymentMethodStatusEnum | "All";

@Component({
  name: "Search",
  components: {
    DialogBox,
    Pagination,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'danger',
        true: 'success',
        null: 'success'
      }
      return statusMap[status]
    },
    stateStatusFilter: (status:OrderSubStatesEnum) => orderSubStatesFilter(status)
  }
})

export default class extends Vue {
  tableData: PagedResultOfPaymentDueOrdersViewModel = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  filter: string = "";
  billingDayOfMonth: number = 1;
  billingDate: string | null = null;
  amount: number | null = null;
  pageNumber = 1;
  pageSize = 20;
  selection: Array<any> = [];
  dialogVisible = false;
  billingDaysDdl: BillingDaysListViewModel[] = [];
  paymentMethodStatus: PaymentMethodStatusWithReset | undefined;  
  paymentMethodStatusOptions: string[] = ["All", "Valid", "Invalid", "AboutToExpire", "Expired"]
  dFn = debounce((val:Function) => val(), '600ms')

  get params() {
    return {
      filter: this.filter,
      pageNumber: this.pageNumber.toString(),
      pageSize: this.pageSize.toString(),
      billingDayOfMonth: this.billingDayOfMonth
    }
  }

  get sortedBillingDaysDdl(): BillingDaysListViewModel[] {
    return this.billingDaysDdl.sort((a, b) => a.dayOfTheMonth - b.dayOfTheMonth);
  }

  async created() {
    let toQuery = {
      filter: this.filter,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      billingDayOfMonth: this.billingDayOfMonth
    }

    this.setQueryWatch(toQuery)
    this.billingDaysDdl = await billingDaysDdlGet();
    this.loadInitialData();
  }

  mounted() {
    setTimeout(() => {
      document.getElementById("search")?.focus();
    }, 0);
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {

        if(typeof (this as any)[field] == 'number') {
          (this as any)[field] = +queries[field];
        } else {
          (this as any)[field] = queries[field];
        }
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  disabledDate(date: Date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison

    // Get the date 6 days from now
    const sizDaysAhead = new Date();
    sizDaysAhead.setDate(today.getDate() + 6);
    sizDaysAhead.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison

    // Check if the provided date is within 6 days from today
    return date < today || date > sizDaysAhead;
  };

  get areAllSelected() {
    let unselected = 0;
    this.tableData.data.forEach((item) => {
      if (!this.selection.includes(item.id)) {
        unselected += 1;
      }
    });

    return unselected === 0 ? true : false;
  }

  handleSelectAll() {
    if (!this.areAllSelected) {
      this.tableData.data.forEach((order) => {
        if (!this.selection.includes(order.id)) {
          this.selection.push(order.id);
        }
      });
    } else {
      this.tableData.data.forEach((order) => {
        this.selection = this.selection.filter((item) => item !== order.id);
      });
    }

    console.log(this.selection);
  }

  handleSelect(id: any) {
    if (this.selection.includes(id)) {
      this.selection = this.selection.filter((item) => item !== id);
    } else {
      this.selection.push(id);
    }
  }

  handleDate(date: any) {
    return formatDate(date);
  }
  
  handleName(name:string) {
    return formatName(name)
  }

  handleBillingDateChange() {
    this.pageNumber = 1;
    this.selection = [];
    this.loadInitialData()
  }

  handleFilter(isClear = false) {
    if(isClear || this.filter.length >= 3) {
      this.dFn(() => {
        this.pageNumber = 1;
        this.loadInitialData().then(() => {
          setTimeout(() => {
            document.getElementById("search")?.focus();
          }, 0);
        })
      })
    }
  }

  clearFilters() {
    this.selection = [];
    this.pageNumber = 1;
    this.filter = "";
    this.billingDayOfMonth = 1

    this.loadInitialData();
  }

  handleCommand(value: string) {
    if(value == "edit") {
      this.dialogVisible = true;
      
      setTimeout(() => {
        this.billingDate = null;
        this.amount = null;
        (document.getElementById("amount") as any).children[0].children[0].value = null
      }, 100);
    }
  }

  handleProcessClose() {
    this.dialogVisible = false;
    this.billingDate = null;
    this.amount = null;
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handlePageSizeChange(size: number) {
    this.pageNumber = 1;
    this.pageSize = size;
    this.loadInitialData()
  }

  handlePaymentMethodStatusChange() {    
    if (this.paymentMethodStatus === "All") {  
      this.paymentMethodStatus = undefined;
      this.pageNumber = 1;
      this.loadInitialData();
    } else {  
      this.pageNumber = 1;
      this.loadInitialData();
    }  
  }  

  processSelection(edited: boolean = false) {
    debugger
    if(edited && (document.getElementById("amount") as any)?.children[0].children[0].value == "") {
      this.amount = null
    }
    
    if(!this.billingDate) {
      const nextBillingDate = this.tableData.data[0].nextBillingDay;
      this.billingDate = nextBillingDate ? (new Date(nextBillingDate) as any) : null;
    }

    this.dialogVisible = false;
    const payload: ApiOrdersProcessPaymentsPostRequest = {
      orderIds: this.selection,
      billingDate: this.billingDate,
      amount: !edited ? null : this.amount
    }

    ordersProcessPaymentsPost(payload).then(() => {
      this.billingDate = this.amount = null;
      this.selection = [];
      this.loadInitialData()
    })
  }

  async loadInitialData() {    
    try {    
      const paymentStatus = this.paymentMethodStatus === "All" ? undefined : this.paymentMethodStatus;  
      const res = await ordersPaymentsDueGet(this.pageNumber, this.pageSize, this.billingDayOfMonth, [], paymentStatus);    
      this.tableData = res;    
    } catch (error) {    
      console.error(error);    
    }    
  }  
}
