
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "DialogBox",
  props: {
    dialogVisible: {default: false},
    cancelVisible: {default: false},
    confirmVisible: {default: true},
    showCloseIcon: {default: true},
    message: {default: ""},
    title: {default: null},
    confirmText: {default: "Ok"},
    confirmDisabled: {default: false},
    width: { default: "30%" },
  }
})
export default class extends Vue {

  handleClose() {  
    this.$emit('update:dialogVisible', false);
  }  
}  

