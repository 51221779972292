import router from "@/router";

export function handleQueries(field: any, value: any) {
  let queries = formatQuery(router.currentRoute.query);

  if (![null, undefined, ''].includes(value)) {
    queries[field] = value;
  } else {
    delete queries[field];
  }

  let newRoute = router.resolve({ name: router.currentRoute.name as string, query: queries }).href
  let oldRoute = router.currentRoute.fullPath

  if(newRoute !== oldRoute) {
    router.replace({ name: router.currentRoute.name as string, query: queries });
  }
}

function formatQuery(query: any) {
  return Object.keys(query).reduce((acc, cur) => {
    acc[cur] = query[cur];
    return acc;
  }, {} as any);
}
